import React from 'react';
import Template from 'modules/ChapterTemplate';
import CalloutStampBlock from 'modules/CalloutStamp';
import InfoParallax from 'modules/InfoParallax';
import InfoCollage from 'modules/InfoCollage';
import Videos from 'modules/Videos';
import List from 'components/List';
import Conclusion from 'modules/Conclusion';
import styled from 'styled-components';
import { theme } from 'config/work.json';
import Noise from 'components/Sound/Noise';
import { withIntl } from 'i18n';

const CalloutStampHeading = styled(CalloutStampBlock)`
  margin: 5rem 0 0;
`;

const Work = () => (
  <Template theme={theme}>
    <Noise id="work/factory1" volume={90}>
      <InfoParallax
        figure={{
          colProps: {
            xs: 12,
            sm: 12,
            smOffset: 0,
            md: 10,
            mdOffset: 1
          },
          image: 'work/madagascar-factory',
          caption: 'work_imageNightshiftCaption',
          captionPadding: {
            lg: '0'
          }
        }}
        infoBox={{
          infoBox: {
            scroll: true,
            size: {
              lg: '30rem',
              md: '25rem',
              sm: '20rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 7,
            smOffset: 2,
            md: 5,
            mdOffset: 3
          },
          message: 'work_infoMostofusBody',
          justify: {
            sm: 'flex-start',
            md: 'flex-start',
            lg: 'flex-start'
          }
        }}
        voffset={{
          lg: '15rem 0 0 0',
          md: '15rem 0 0 0',
          sm: '20rem 0 0 0',
          xs: '0'
        }}
      />
    </Noise>
    <CalloutStampBlock stamp="pinkcircle" calloutId="work_calloutRoleofilo" />
    <Noise id="work/fishing" volume={90}>
      <InfoParallax
        figure={{
          colProps: {
            xs: 12,
            sm: 12,
            md: 8,
            mdOffset: 0
          },
          image: 'work/fishermen-philippines',
          caption: 'work_imageFishersCaption',
          captionAlign: 'ltr',
          captionPadding: {
            lg: '0 50% 0 0',
            md: '0 35% 0 0'
          }
        }}
        infoBox={{
          infoBox: {
            scroll: true,
            size: {
              lg: '30rem',
              md: '20rem'
            }
          },
          caption: {},
          colProps: {
            xs: 12,
            smOffset: 2,
            sm: 7,
            md: 5,
            mdOffset: 6,
            lg: 6,
            lgOffset: 5
          },
          message: 'work_infoLabourstdsBody',
          justify: {
            sm: 'flex-start',
            md: 'flex-end',
            lg: 'flex-end'
          }
        }}
        voffset={{
          lg: '0 0 10rem',
          md: '0',
          sm: '18rem 0 0',
          xs: '0'
        }}
      />
    </Noise>
    <InfoCollage
      collage={{
        colProps: {
          xs: 12,
          md: 10,
          lg: 10,
          lgOffset: -1
        },
        layers: [
          'work/war-workers-1',
          'work/war-workers-2',
          'work/war-workers-3'
        ],
        caption: {
          message: 'work_imageWomenwarCaption',
          colProps: {
            xs: 12,
            sm: 8,
            smOffset: 2,
            md: 5,
            mdOffset: 1,
            lgOffset: 2
          }
        }
      }}
      infoBoxes={[
        {
          infoBox: {
            scroll: true,
            size: {
              lg: '35rem',
              md: '25rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 10,
            smOffset: 1,
            md: 5,
            mdOffset: 6,
            lg: 6
          },
          message: 'work_infoKeepingyouBody',
          justify: 'flex-end',
          position: {
            sm: 'relative'
          }
        }
      ]}
      voffset={{
        sm: '0 0 0rem',
        lg: '0 0 10rem',
        md: '0 0 10rem',
      }}
    />
    <CalloutStampBlock
      stamp="pinkcircle"
      calloutId="work_calloutLawscompliedTitle"
      subtextId="work_calloutLawscompliedBody"
    />
    <Noise id="work/factory2" volume={90}>
      <InfoParallax
        figure={{
          colProps: {
            xs: 12,
            sm: 12,
            md: 9,
            mdOffset: 0
          },
          image: 'work/jordan-inspection',
          caption: 'work_imageLabourinspCaption',
          captionAlign: 'ltr',
          captionPadding: {
            lg: '0 50% 0 0',
            md: '0 25% 0 0'
          }
        }}
        infoBox={{
          infoBox: {
            scroll: true,
            size: {
              lg: '28rem',
              md: '20rem'
            }
          },
          colProps: {
            xs: 12,
            smOffset: 3,
            sm: 8,
            md: 5,
            mdOffset: 6,
            lg: 6,
            lgOffset: 5
          },
          message: 'work_infoFrontlineBody',
          justify: {
            sm: 'flex-start',
            md: 'center',
            lg: 'center'
          }
        }}
        voffset={{
          lg: '0',
          md: '0',
          sm: '25rem 0 0',
          xs: '0'
        }}
      />
    </Noise>
    <Videos videos="work/videoValkyrie" />
    <CalloutStampHeading
      stamp="fingerprint"
      calloutId="work_calloutFourareas"
    />
    <List
      items={[
        {
          description: 'work_listFitforpurpose1Title',
          subtext: 'work_listFitforpurpose1Body',
          detailMessages: [
            'work_detailFitforpurpose1',
            'work_detailFitforpurpose2'
          ],
          detailFigure: {
            image: 'work/detail-Fitforpurpose',
            caption: 'work_imageFitforpurposeCaption'
          }
        },
        {
          description: 'work_listLawmatters2Title',
          subtext: 'work_listLawmatters2Body',
          detailMessages: ['work_detailLawmatters1'],
          detailFigure: {
            image: 'work/detail-law',
            caption: 'work_imageLawmattersCaption'
          }
        },
        {
          description: 'work_listWorkingtog3Title',
          subtext: 'work_listWorkingtog3Body',
          detailMessages: ['work_detailWorkingtog1', 'work_detailWorkingtog2'],
          detailFigure: {
            image: 'work/detail-together',
            caption: 'work_imageWorkingtoCaption'
          }
        },
        {
          description: 'work_listReaching4Title',
          subtext: 'work_listReaching4Body',
          detailMessages: ['work_detailReaching1', 'work_detailReaching2'],
          detailFigure: {
            image: 'work/detail-consensus',
            caption: 'work_imageConsensusCaption'
          }
        }
      ]}
    />
    <Videos videos="work/videoMeyer" />
    <InfoCollage
      collage={{
        colProps: {
          xs: 12,
          md: 10,
          lg: 10,
          mdOffset: 3
        },
        layers: [
          'work/pakistan-girl-1',
          'work/pakistan-girl-2',
          'work/pakistan-girl-3'
        ],
        caption: {
          message: 'work_imageChildcarpetCaption',
          colProps: {
            xs: 12,
            sm: 10,
            smOffset: 2,
            mdOffset: 4,
            md: 6
          }
        }
      }}
      infoBoxes={[
        {
          infoBox: {
            scroll: true,
            size: {
              lg: '30rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 10,
            smOffset: 1,
            md: 5,
            mdOffset: 0,
            lg: 6
          },
          message: 'work_infoBrokenrulesBody',
          justify: 'flex-end',
          position: {
            sm: 'relative'
          }
        }
      ]}
      voffset={{
        sm: '0 0 0rem',
        lg: '0 0 18rem',
        md: '0 0 10rem'
      }}
    />
    <InfoCollage
      collage={{
        colProps: {
          xs: 12,
          md: 8,
          lg: 8,
          mdOffset: 4
        },
        layers: [
          'work/qatar-worker-1',
          'work/qatar-worker-2',
          'work/qatar-worker-3'
        ],
        caption: {
          message: 'work_imageMigrantCaption',
          colProps: {
            xs: 12,
            sm: 8,
            smOffset: 3,
            mdOffset: 5,
            md: 4
          }
        }
      }}
      infoBoxes={[
        {
          infoBox: {
            scroll: true,
            size: {
              lg: '30rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 10,
            smOffset: 1,
            md: 5,
            mdOffset: 0,
            lg: 6
          },
          message: 'work_infoTacklingBody',
          justify: 'flex-end',
          position: {
            sm: 'relative'
          }
        }
      ]}
      voffset={{
        sm: '0 0 0rem',
        lg: '0 0 10rem',
        md: '0 0 10rem'
      }}
    />
    <CalloutStampBlock
      stamp="circle"
      calloutId="work_calloutTrappedflTitle"
      subtextId="work_calloutTrappedflBody"
    />
    <InfoParallax
      figure={{
        colProps: {
          xs: 12,
          sm: 12,
          smOffset: 0,
          md: 9,
          mdOffset: 3
        },
        image: 'work/syrian-migrant',
        caption: 'work_imageSyrianCaption',
        captionPadding: {
          lg: '0 0 0 55%',
          md: '0 0 0 50%',
        }
      }}
      infoBox={{
        infoBox: {
          scroll: true,
          size: {
            lg: '28rem',
            md: '25rem',
            sm: '20rem'
          }
        },
        colProps: {
          xs: 12,
          smOffset: 2,
          sm: 7,
          mdOffset: 0,
          md: 5,
          lg: 6
        },
        message: 'work_infoInresponseBody',
        justify: {
          sm: 'flex-start',
          md: 'flex-start',
          lg: 'flex-start'
        }
      }}
      voffset={{
        lg: '0',
        md: '0',
        sm: '25rem 0 0',
        xs: '0'
      }}
    />
    <Conclusion
      mainTextId="work_calloutIloaimstoTitle"
      subTextId="work_calloutIloaimstoBody"
    />
  </Template>
);

export default withIntl(Work);
